import type { AbilityBuilder } from "@casl/ability";
import { FILE_ORIGIN } from "~/constants/file";
import { ORGANISATIONS_USERS_ROLES } from "~/constants/organisations";
import { APPLICATION_STEP, LIQUIDATION_STEP, ORGANISATION_STEP } from "~/constants/steps/steps";
import { VALIDATION_SUBJECT } from "~/constants/validations";
import type { User } from "~/types/database";
import type { FlatAppAbility } from "~/types/permissions";
export const getApplicantPermissions = ({
  can,
  applicant
}: {
  can: AbilityBuilder<FlatAppAbility>["_addRule"];
  applicant: User;
}) => {
  // Dossier as Applicant
  can("read", "dossiers", ["id", "application_form_answers"], {
    applicant_id: applicant.id
  });
  can("create", "dossiers", "id", {
    step: {
      $eq: APPLICATION_STEP.SUBMISSION
    }
  });
  can("read", "dossiers", "control.modification", {
    applicant_id: applicant.id,
    step: {
      $eq: APPLICATION_STEP.MODIFICATION
    }
  });
  can("read", "dossiers", ["subvention_projects", "liquidation"], {
    applicant_id: applicant.id,
    step: {
      $in: [APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CONTROL, APPLICATION_STEP.CLOSURE, APPLICATION_STEP.ARCHIVED, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.CONTROL_VERIFICATION]
    }
  });
  can("read", "dossiers", "control_requested", {
    applicant_id: applicant.id,
    step: {
      $in: [APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.ARCHIVED]
    }
  });
  can("read", "dossiers", "control_requested.modification", {
    applicant_id: applicant.id,
    step: {
      $in: [APPLICATION_STEP.MODIFICATION, APPLICATION_STEP.VERIFICATION]
    }
  });
  can("read", "dossiers", "control_requested.modification", {
    applicant_id: applicant.id,
    step: {
      $in: [APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_VERIFICATION]
    },
    "validationsData.subject": {
      $eq: VALIDATION_SUBJECT.CONTROL_COMPLETENESS
    },
    "validationsData.approved": false
  });
  can("update", "dossiers", "id", {
    step: {
      $eq: APPLICATION_STEP.MODIFICATION
    },
    applicant_id: applicant.id
  });
  can("update", "dossiers", ["project_name", "application_form_answers", "all"], {
    applicant_id: applicant.id,
    step: {
      $in: [APPLICATION_STEP.SUBMISSION, APPLICATION_STEP.MODIFICATION]
    }
  });
  can("delete", "dossiers", {
    step: {
      $eq: APPLICATION_STEP.SUBMISSION
    },
    applicant_id: applicant.id
  });
  can("seeFo", "dossiers");

  // Dossier ad Admin of Organisation
  can("read", "dossiers", ["id", "application_form_answers"], {
    // FIXME: the type
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "organisation.memberships": {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    },
    step: {
      $nin: [APPLICATION_STEP.SUBMISSION, APPLICATION_STEP.ARCHIVED]
    }
  });
  can("read", "dossiers", "control.modification", {
    // FIXME: the type
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "organisation.memberships": {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    },
    step: {
      $eq: APPLICATION_STEP.MODIFICATION
    }
  });
  can("read", "dossiers", ["subvention_projects", "liquidation"], {
    // FIXME: the type
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "organisation.memberships": {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    },
    step: {
      $in: [APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CONTROL, APPLICATION_STEP.CLOSURE, APPLICATION_STEP.ARCHIVED, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.CONTROL_VERIFICATION]
    }
  });
  can("read", "dossiers", "control_requested", {
    // FIXME: the type
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "organisation.memberships": {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    },
    step: {
      $in: [APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT]
    }
  });
  can("read", "dossiers", "control_requested.modification", {
    // FIXME: the type
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "organisation.memberships": {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    },
    step: {
      $in: [APPLICATION_STEP.MODIFICATION, APPLICATION_STEP.VERIFICATION]
    }
  });
  can("read", "dossiers", "control_requested.modification", {
    "organisation.memberships": {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    },
    step: {
      $in: [APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_VERIFICATION]
    },
    "validationsData.subject": {
      $eq: VALIDATION_SUBJECT.CONTROL_COMPLETENESS
    },
    "validationsData.approved": false
  });
  can("update", "dossiers", "applicant_id", {
    // FIXME: the type
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "organisation.memberships": {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    },
    step: {
      $nin: [APPLICATION_STEP.SUBMISSION, APPLICATION_STEP.ARCHIVED]
    }
  });
  can("read", "dossiers", "organisation.isActive", {
    applicant_id: applicant.id
  });

  //Liquidation
  can("read", "liquidations", "request_change", {
    step: {
      $eq: LIQUIDATION_STEP.MODIFICATION
    }
  });
  can("delete", "liquidations", {
    created_by: applicant.id,
    step: {
      $in: [LIQUIDATION_STEP.SUBMISSION]
    }
  });
  can("update", "liquidations", ["id", "all"], {
    step: {
      $in: [LIQUIDATION_STEP.MODIFICATION, LIQUIDATION_STEP.SUBMISSION]
    }
  });
  can("create", "dossiers", "liquidation", {
    applicant_id: applicant.id,
    step: {
      $eq: APPLICATION_STEP.ACTIVE
    }
  });
  can("create", "liquidations", "id");
  can("delete", "files", {
    origin: {
      $in: [FILE_ORIGIN.FROM_APPLICANT_APPLICATION_FORM_ANSWER, FILE_ORIGIN.FROM_APPLICANT_CLAIM_STATEMENT, FILE_ORIGIN.FROM_APPLICANT_CONTROL_BY_LIQUIDATION]
    }
  }); // since can update liquidation form, should be able to delete files uploaded by applicant

  //CONTROL
  can("read", "dossiers", "control_files", {
    applicant_id: applicant.id,
    step: {
      $in: [APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.ARCHIVED]
    }
  });
  can("read", "dossiers", "control_files", {
    // FIXME: the type
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "organisation.memberships": {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    },
    step: {
      $in: [APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.ARCHIVED]
    }
  });
  can("create", "dossiers", "control_files", {
    applicant_id: applicant.id,
    step: {
      $in: [APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_MODIFICATION]
    }
  });

  // Users
  can("read", "users", "id", {
    id: applicant.id
  });
  can("read", "users", "roles", {
    id: {
      $eq: applicant.id
    }
  });
  can("create", "users", "beneficiary_authorisations.beneficiaries", {
    id: applicant.id
  });
  can("delete", "users", "beneficiary_authorisations.beneficiaries", {
    id: applicant.id
  });
  can("delete", "users", "beneficiaries", {
    id: applicant.id
  });
  can("update", "users", "all", {
    id: applicant.id
  });

  // Organisations
  can("read", "organisations", "validations", {
    memberships: {
      $elemMatch: {
        user_id: applicant.id
      }
    },
    step: ORGANISATION_STEP.MODIFICATION
  });
  can("read", "organisations", "id", {
    memberships: {
      $elemMatch: {
        user_id: applicant.id
      }
    }
  });
  can("read", "organisations", "id", {
    step: {
      $eq: ORGANISATION_STEP.APPROVED
    }
  });

  // Can edit an organisation which is approved
  can("update", "organisations", "id", {
    step: {
      $eq: ORGANISATION_STEP.APPROVED
    },
    memberships: {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    }
  });
  can("update", "organisations", "step", {
    step: {
      $eq: ORGANISATION_STEP.MODIFICATION
    },
    memberships: {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    }
  });
  can("delete", "organisations", "id", {
    step: {
      $in: [ORGANISATION_STEP.MODIFICATION, ORGANISATION_STEP.VALIDATION]
    },
    memberships: {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    }
  });

  // Organisation action table
  can("read", "organisations", "actions", {
    memberships: {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    }
  });

  // Organisation Memberships
  can("read", "organisations", "memberships", {
    step: {
      $in: [ORGANISATION_STEP.APPROVED]
    },
    memberships: {
      $elemMatch: {
        user_id: applicant.id
      }
    }
  });
  can("create", "organisations", "memberships", {
    step: {
      $eq: ORGANISATION_STEP.APPROVED
    },
    memberships: {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    }
  });
  can("delete", "organisations", "memberships", {
    step: {
      $eq: ORGANISATION_STEP.APPROVED
    },
    memberships: {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    }
  });
  can("update", "organisations", "memberships.role", {
    step: {
      $eq: ORGANISATION_STEP.APPROVED
    },
    memberships: {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    }
  });

  // Organisation members authorisation files
  can("read", "organisations", "memberships.authorisation_files", {
    step: {
      $ne: ORGANISATION_STEP.APPROVED
    },
    memberships: {
      $elemMatch: {
        user_id: applicant.id
      }
    }
  });
  can("update", "organisations", "memberships.authorisation_files", {
    step: {
      $eq: ORGANISATION_STEP.MODIFICATION
    },
    memberships: {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    }
  });

  // Organisation validations
  can("read", "organisations", "validations", {
    memberships: {
      $elemMatch: {
        user_id: applicant.id
      }
    },
    step: {
      $eq: ORGANISATION_STEP.MODIFICATION
    },
    validations: {
      $elemMatch: {
        approved: false
      }
    } // Don't think this is needed since we have the organisation step
  });

  // Organisation beneficiaries
  can("create", "organisations", "beneficiary_authorisations.beneficiaries", {
    step: {
      $in: [ORGANISATION_STEP.MODIFICATION, ORGANISATION_STEP.APPROVED]
    },
    memberships: {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    }
  });
  can("delete", "organisations", "beneficiary_authorisations.beneficiaries", {
    step: {
      $in: [ORGANISATION_STEP.MODIFICATION, ORGANISATION_STEP.APPROVED]
    },
    memberships: {
      $elemMatch: {
        user_id: applicant.id,
        role: {
          $in: [ORGANISATIONS_USERS_ROLES.ADMIN]
        }
      }
    }
  });

  //files

  can("delete", "files");
};