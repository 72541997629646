import type { AbilityBuilder } from "@casl/ability";
import { FILE_ORIGIN } from "~/constants/file";
import { APPLICATION_STEP, LIQUIDATION_STEP, SUBVENTION_PROJECT_STEP } from "~/constants/steps/steps";
import { ROLES } from "~/constants/users";
import { VALIDATION_SUBJECT } from "~/constants/validations";
import type { User } from "~/types/database";
import type { FlatAppAbility } from "~/types/permissions";
import { getServiceIdsAsRoleType, getUserRoleTypes } from "~/utils/user-role";
export const getHandlerPermissions = ({
  can,
  handler,
  isHandlerManager
}: {
  can: AbilityBuilder<FlatAppAbility>["_addRule"];
  handler: User;
  isHandlerManager: boolean;
}) => {
  const userRoleTypes = getUserRoleTypes(handler);
  const serviceIdsAsHandler = isHandlerManager ? getServiceIdsAsRoleType(handler, ROLES.HANDLER_MANAGER) : getServiceIdsAsRoleType(handler, ROLES.HANDLER);

  //Organizations
  can("read", "organisations", "list");

  // Dispositifs
  can(["create", "read"], "dispositifs", {
    service_id: {
      $in: serviceIdsAsHandler
    }
  });
  can("update", "dispositifs", "id", {
    status: "draft",
    service_id: {
      $in: serviceIdsAsHandler
    }
  });
  can("update", "dispositifs", ["label", "status"], {
    status: "published",
    service_id: {
      $in: serviceIdsAsHandler
    }
  });
  // archive
  can("delete", "dispositifs", {
    status: "draft",
    service_id: {
      $in: serviceIdsAsHandler
    }
  });
  can("create", "dossiers", "paper_application_files", {
    handler_id: handler.id,
    step: {
      $eq: APPLICATION_STEP.SUBMISSION
    }
  });

  // Services
  can("read", "services", {
    users_roles: {
      $elemMatch: {
        service_id: {
          $in: serviceIdsAsHandler
        }
      }
    }
  });
  can("seeBo", "dossiers");
  can("read", "dossiers", ["id", "application_form_answers"], {
    service_id: {
      $in: serviceIdsAsHandler
    }
  });
  can("create", "dossiers", "Bo");
  can("read", "dossiers", "validationsData.decision", {
    step: {
      $nin: [APPLICATION_STEP.SUBMISSION, APPLICATION_STEP.ASSIGNMENT, APPLICATION_STEP.VERIFICATION, APPLICATION_STEP.MODIFICATION, APPLICATION_STEP.DECISION]
    }
  });
  can("read", "dossiers", "id", {
    service_id: {
      $in: serviceIdsAsHandler
    }
  });
  can("create", "dossiers", "id", {
    step: {
      $eq: APPLICATION_STEP.SUBMISSION
    }
  });
  can("read", "dossiers", "applicant.isActive", {
    "applicant.is_active": {
      $eq: false
    }
  });
  can("read", "dossiers", "organisation.isActive");
  can("read", "dossiers", "application_form_answers");
  can("update", "dossiers", ["application_form_answers", "all"], {
    step: {
      $in: [APPLICATION_STEP.SUBMISSION]
    },
    created_by: handler.id
  });
  if (isHandlerManager) {
    can("update", "dossiers", "handler_id", {
      service_id: {
        $in: serviceIdsAsHandler
      },
      step: {
        $nin: [APPLICATION_STEP.ARCHIVED, APPLICATION_STEP.SUBMISSION]
      }
    });
  }
  can("delete", "dossiers", {
    step: {
      $eq: APPLICATION_STEP.SUBMISSION
    },
    created_by: handler.id
  });
  can(["create", "read"], "memos");
  can("delete", "memos", {
    user_id: handler.id
  });
  can("update", "dossiers", "handler_title", {
    handler_id: handler.id,
    service_id: {
      $in: serviceIdsAsHandler
    },
    step: {
      $nin: [APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CONTROL, APPLICATION_STEP.CLOSURE, APPLICATION_STEP.ARCHIVED]
    },
    subvention_projects: {
      $exists: true
    }
  });
  can("update", "dossiers", "handler_title", {
    handler_id: handler.id,
    service_id: {
      $in: serviceIdsAsHandler
    },
    step: {
      $nin: [APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CONTROL, APPLICATION_STEP.CLOSURE, APPLICATION_STEP.ARCHIVED]
    },
    subvention_projects: {
      $elemMatch: {
        step: {
          $nin: [SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_AMENDMENT_DECREE_SIGNATURE, SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_GOOD_NEWS_LETTER_SIGNATURE, SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_SECOND_VALIDATION, SUBVENTION_PROJECT_STEP.AUTHORIZING_OFFICER_GRANT_DECREE_SIGNATURE, SUBVENTION_PROJECT_STEP.MINISTER_AMENDMENT_DECREE_SIGNATURE, SUBVENTION_PROJECT_STEP.MINISTER_GRANT_DECREE_SIGNATURE, SUBVENTION_PROJECT_STEP.MINISTER_VALIDATION]
        }
      }
    }
  });
  can("update", "dossiers", "handler_title", {
    handler_id: handler.id
  });
  can("update", "dossiers", "applicant_id", {
    organisation: {
      $exists: true
    },
    handler_id: handler.id,
    step: {
      $nin: [APPLICATION_STEP.SUBMISSION, APPLICATION_STEP.ARCHIVED]
    }
  });
  can("read", "dossiers", "validationsData.decision", {
    handler_id: handler.id,
    step: {
      $nin: [APPLICATION_STEP.SUBMISSION, APPLICATION_STEP.VERIFICATION, APPLICATION_STEP.MODIFICATION, APPLICATION_STEP.DECISION]
    }
  });
  can("read", "dossiers", "control.modification", {
    step: {
      $in: [APPLICATION_STEP.MODIFICATION, APPLICATION_STEP.VERIFICATION]
    }
  });
  can("create", "dossiers", "validationsData.completed", {
    handler_id: handler.id,
    step: {
      $eq: APPLICATION_STEP.VERIFICATION
    }
  });
  can("create", "dossiers", "validationsData.decision", {
    handler_id: handler.id,
    step: {
      $eq: APPLICATION_STEP.DECISION
    }
  });
  can("update", "dossiers", "validationsData.decision", {
    handler_id: handler.id,
    step: {
      $eq: APPLICATION_STEP.REDACTION
    }
  });
  can("delete", "files");
  can(["read", "create"], "dossiers", "bad_news_letter_file", {
    handler_id: handler.id,
    step: {
      $eq: APPLICATION_STEP.REFUSAL_BAD_NEWS_LETTER_WRITING
    }
  });
  can("update", "dossiers", "bad_news_letter", {
    handler_id: handler.id,
    step: {
      $in: [APPLICATION_STEP.REFUSAL_BAD_NEWS_LETTER_WRITING, APPLICATION_STEP.REFUSAL_MODIFICATION]
    }
  });
  can("update", "dossiers", "bad_news_letter_file", {
    handler_id: handler.id,
    step: {
      $eq: APPLICATION_STEP.REFUSAL_MODIFICATION
    }
  });
  can("update", "dossiers", "closed_early", {
    handler_id: handler.id,
    step: {
      $eq: APPLICATION_STEP.ACTIVE
    }
  });

  // Subvention
  can("read", "dossiers", "subvention_projects", {
    handler_id: handler.id,
    step: {
      $in: [APPLICATION_STEP.REDACTION, APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CONTROL, APPLICATION_STEP.CLOSURE, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.ARCHIVED]
    }
  });
  can("create", "dossiers", "subvention_projects", {
    step: {
      $in: [APPLICATION_STEP.REDACTION, APPLICATION_STEP.ACTIVE]
    }
  });
  can("update", "dossiers", "subvention_projects", {
    subvention_projects: {
      $elemMatch: {
        step: {
          $in: [SUBVENTION_PROJECT_STEP.CREATION, SUBVENTION_PROJECT_STEP.MODIFICATION, SUBVENTION_PROJECT_STEP.FINANCIAL_INSPECTOR_OPINION]
        }
      }
    }
  });
  can("update", "subvention_projects", "budget_proposal_details", {
    step: {
      $in: [SUBVENTION_PROJECT_STEP.CREATION, SUBVENTION_PROJECT_STEP.MODIFICATION]
    }
  });
  can("delete", "subvention_projects", {
    step: SUBVENTION_PROJECT_STEP.CREATION
  });
  can("update", "subvention_projects", "all", {
    step: {
      $in: [SUBVENTION_PROJECT_STEP.CREATION, SUBVENTION_PROJECT_STEP.MODIFICATION]
    }
  });
  can("read", "subvention_projects", "validations.opinion", {
    step: {
      $eq: SUBVENTION_PROJECT_STEP.FINANCIAL_INSPECTOR_OPINION
    }
  });
  can("create", "subvention_projects", "validations", {
    step: {
      $eq: SUBVENTION_PROJECT_STEP.FINANCIAL_INSPECTOR_OPINION
    }
  });

  // PIA database
  can("read", "PIA-database");

  // Liquidation
  can("read", "dossiers", "liquidation", {
    handler_id: handler.id,
    step: {
      $in: [APPLICATION_STEP.ACTIVE, APPLICATION_STEP.CONTROL, APPLICATION_STEP.CLOSURE, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.ARCHIVED]
    }
  });
  can("read", "dossiers", "request_change");
  can("create", "dossiers", "liquidation", {
    handler_id: handler.id,
    step: {
      $eq: APPLICATION_STEP.ACTIVE
    }
  });
  can("read", "liquidations", "id", {
    step: {
      $in: [LIQUIDATION_STEP.VERIFICATION]
    }
  });
  can("delete", "liquidations", {
    created_by_role: {
      $in: userRoleTypes
    },
    step: LIQUIDATION_STEP.SUBMISSION
  });
  can("update", "liquidations", "id", {
    created_by_role: {
      $in: userRoleTypes
    },
    step: {
      $in: [LIQUIDATION_STEP.SUBMISSION]
    }
  });
  can("create", "liquidations", "id", {
    step: {
      $in: [LIQUIDATION_STEP.SUBMISSION]
    }
  });
  can("update", "liquidations", "Bo", {
    step: {
      $in: [LIQUIDATION_STEP.VERIFICATION]
    }
  });
  can("delete", "files", {
    origin: {
      $in: [FILE_ORIGIN.FROM_APPLICANT_CLAIM_STATEMENT, FILE_ORIGIN.FROM_APPLICANT_CONTROL_BY_LIQUIDATION]
    }
  }); // since can update liquidation form, should be able to delete files uploaded by applicant

  can("read", "liquidations", {
    step: {
      $in: [LIQUIDATION_STEP.MODIFICATION]
    }
  });
  can("update", "liquidations", "all", {
    created_by_role: {
      $in: userRoleTypes
    },
    step: LIQUIDATION_STEP.SUBMISSION
  });
  can("read", "dossiers", "closureCard", {
    handler_id: handler.id,
    step: {
      $eq: APPLICATION_STEP.CLOSURE
    }
  });

  // Control
  can("read", "dossiers", "control_requested", {
    handler_id: handler.id,
    control_requested: {
      $eq: true
    },
    step: {
      $in: [APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT]
    }
  });
  can("read", "dossiers", "control_requested", {
    handler_id: handler.id,
    step: {
      $in: [APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT]
    }
  });
  can("read", "dossiers", "control_requested", {
    handler_id: handler.id,
    controlled: true,
    step: APPLICATION_STEP.ARCHIVED
  });
  can("read", "dossiers", "control_requested", {
    handler_id: handler.id,
    closed_early: true,
    has_overpayment: true,
    step: {
      $in: [APPLICATION_STEP.ARCHIVED, APPLICATION_STEP.CONTROL_OVERPAYMENT]
    }
  });
  can("create", "dossiers", "controlled", {
    handler_id: handler.id,
    step: APPLICATION_STEP.CLOSURE
  });
  can("read", "dossiers", "control_requested.modification", {
    handler_id: handler.id,
    step: {
      $in: [APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_VERIFICATION]
    },
    "validationsData.subject": {
      $eq: VALIDATION_SUBJECT.CONTROL_COMPLETENESS
    },
    "validationsData.approved": false
  });
  can("create", "dossiers", "validations.control", {
    handler_id: handler.id,
    step: APPLICATION_STEP.CONTROL_VERIFICATION
  });
  can("read", "dossiers", "control_files", {
    closed_early: false,
    handler_id: handler.id,
    step: {
      $in: [APPLICATION_STEP.CONTROL, APPLICATION_STEP.CONTROL_VERIFICATION, APPLICATION_STEP.CONTROL_MODIFICATION, APPLICATION_STEP.CONTROL_OVERPAYMENT]
    }
  });
  can("read", "dossiers", "control_files", {
    closed_early: false,
    handler_id: handler.id,
    controlled: true,
    step: APPLICATION_STEP.ARCHIVED
  });
  can("create", "dossiers", "validations.control_files", {
    handler_id: handler.id,
    step: {
      $eq: APPLICATION_STEP.CONTROL_VERIFICATION
    }
  });

  // Archive
  can("update", "dossiers", "step.archived", {
    handler_id: handler.id,
    step: APPLICATION_STEP.CLOSURE
  });

  // PDF DOCUMENTS
  can("read", "pdf_documents");
};